var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        [
          _c(
            "div",
            [
              _c("h4", { staticClass: "d-inline" }, [
                _c("i", { staticClass: "fa fa-users" }),
                _vm._v(" All Managers")
              ]),
              _c(
                "router-link",
                {
                  staticClass: "float-right btn btn-success",
                  attrs: {
                    to: {
                      name: "Admin Add Manager",
                      params: { id: _vm.$route.params.id }
                    }
                  }
                },
                [_vm._v("Add Manager "), _c("i", { staticClass: "fa fa-plus" })]
              )
            ],
            1
          ),
          _c("hr", { staticClass: "my-4" }),
          _c("v-client-table", {
            attrs: {
              columns: _vm.columns,
              data: _vm.managerData,
              options: _vm.options,
              theme: _vm.theme,
              id: "dataTable"
            },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(props) {
                  return _c("div", {}, [
                    props.row.status === 0
                      ? _c("span", [
                          _c(
                            "button",
                            { staticClass: "btn btn-sm btn-success" },
                            [_vm._v("Active")]
                          )
                        ])
                      : _vm._e(),
                    props.row.status !== 0
                      ? _c("span", [
                          _c(
                            "button",
                            { staticClass: "btn btn-sm btn-danger" },
                            [_vm._v("Inactive")]
                          )
                        ])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "creation_date",
                fn: function(props) {
                  return _c("span", {}, [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(props.row.creation_date, "M/D/YYYY")
                      )
                    )
                  ])
                }
              },
              {
                key: "actions",
                fn: function(props) {
                  return _c(
                    "div",
                    { attrs: { "data-boundary": "window" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2 btn-sm",
                          on: {
                            click: function($event) {
                              return _vm.sendInfo(props.row)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(" Edit")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.editManagerPassword",
                              modifiers: { editManagerPassword: true }
                            }
                          ],
                          staticClass: "btn-sm",
                          on: {
                            click: function($event) {
                              return _vm.sendManagerId(props.row.managerid)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(" Password")
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "clearfix mt-4" },
            [
              _c("h4", { staticClass: "d-inline" }, [
                _c("i", { staticClass: "fa fa-users" }),
                _vm._v(" All Accountants")
              ]),
              _c(
                "router-link",
                {
                  staticClass: "float-right btn btn-success",
                  attrs: {
                    to: {
                      name: "Admin Add Accountant",
                      params: { id: _vm.$route.params.id }
                    }
                  }
                },
                [
                  _vm._v("Add Accountant "),
                  _c("i", { staticClass: "fa fa-plus" })
                ]
              )
            ],
            1
          ),
          _c("hr", { staticClass: "my-4" }),
          _c("v-client-table", {
            attrs: {
              columns: _vm.acolumns,
              data: _vm.accountantData,
              options: _vm.options,
              theme: _vm.theme,
              id: "dataTable"
            },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(props) {
                  return _c("div", {}, [
                    props.row.status === 0
                      ? _c("span", [
                          _c(
                            "button",
                            { staticClass: "btn btn-sm btn-success" },
                            [_vm._v("Active")]
                          )
                        ])
                      : _vm._e(),
                    props.row.status !== 0
                      ? _c("span", [
                          _c(
                            "button",
                            { staticClass: "btn btn-sm btn-danger" },
                            [_vm._v("Inactive")]
                          )
                        ])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "creation_date",
                fn: function(props) {
                  return _c("span", {}, [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(props.row.creation_date, "M/D/YYYY")
                      )
                    )
                  ])
                }
              },
              {
                key: "actions",
                fn: function(props) {
                  return _c(
                    "div",
                    { attrs: { "data-boundary": "window" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2 btn-sm",
                          on: {
                            click: function($event) {
                              return _vm.sendInfo(props.row)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(" Edit")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.editManagerPassword",
                              modifiers: { editManagerPassword: true }
                            }
                          ],
                          staticClass: "btn-sm",
                          on: {
                            click: function($event) {
                              return _vm.sendManagerId(props.row.managerid)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(" Password")
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          }),
          _c("h4", { staticClass: "mt-4" }, [
            _c("i", { staticClass: "nav-icon icon-user" }),
            _vm._v(" Company Profile")
          ]),
          _c("hr"),
          _c("b-table", {
            attrs: {
              striped: "",
              stacked: "",
              fields: _vm.fields,
              items: _vm.companyData
            },
            scopedSlots: _vm._u([
              {
                key: "creation_date",
                fn: function(data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("moment")(data.item.creation_date, "M/D/YYYY")
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "editModal",
          attrs: {
            id: "editManager",
            title: "Edit Manager",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("label", [_vm._v("First Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "fname",
                      placeholder: "First Name"
                    },
                    model: {
                      value: _vm.fname,
                      callback: function($$v) {
                        _vm.fname = $$v
                      },
                      expression: "fname"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("fname"),
                    expression: "errors.has('fname')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("fname"),
                      expression: "errors.has('fname')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The first name is required.")]
              ),
              _c("label", [_vm._v("Last Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "lname",
                      placeholder: "Last Name"
                    },
                    model: {
                      value: _vm.lname,
                      callback: function($$v) {
                        _vm.lname = $$v
                      },
                      expression: "lname"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("lname"),
                    expression: "errors.has('lname')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("lname"),
                      expression: "errors.has('lname')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The last name is required.")]
              ),
              this.authority !== 6
                ? _c(
                    "div",
                    [
                      _c("hr"),
                      _c("label", [_vm._v("Authority")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.authority,
                                  expression: "authority"
                                }
                              ],
                              staticClass: "select-account form-control",
                              attrs: { name: "authority" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.authority = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("1")
                              ]),
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v("2")
                              ]),
                              _c("option", { attrs: { value: "3" } }, [
                                _vm._v("3")
                              ]),
                              _c("option", { attrs: { value: "4" } }, [
                                _vm._v("4")
                              ]),
                              _c("option", { attrs: { value: "5" } }, [
                                _vm._v("5")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("authority"),
                            expression: "errors.has('authority')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("authority"),
                              expression: "errors.has('authority')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The authority number is required.")]
                      ),
                      _c("label", [_vm._v("Pass Limit")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-dollar-sign" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              name: "passlimit",
                              placeholder: "dollar-sign Limit"
                            },
                            model: {
                              value: _vm.passlimit,
                              callback: function($$v) {
                                _vm.passlimit = $$v
                              },
                              expression: "passlimit"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("passlimit"),
                            expression: "errors.has('passlimit')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("passlimit"),
                              expression: "errors.has('passlimit')"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [_vm._v("The dollar-sign limit number is required.")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("hr"),
              _c("label", [_vm._v("Phone Number")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", {
                          staticClass: "fa fa-phone fa-flip-horizontal"
                        })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|digits:10",
                        expression: "'required|digits:10'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      name: "phone",
                      placeholder: "Phone Number"
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("phone"),
                    expression: "errors.has('phone')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("phone"),
                      expression: "errors.has('phone')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v(_vm._s(_vm.errors.first("phone")))]
              ),
              _c("label", [_vm._v("Fax Number")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-fax" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "digits:10",
                        expression: "'digits:10'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "fax",
                      placeholder: "Fax Number"
                    },
                    model: {
                      value: _vm.fax,
                      callback: function($$v) {
                        _vm.fax = $$v
                      },
                      expression: "fax"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("fax"),
                    expression: "errors.has('fax')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("fax"),
                      expression: "errors.has('fax')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v(_vm._s(_vm.errors.first("fax")))]
              ),
              _c("hr"),
              _c("label", [_vm._v("Email")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-envelope" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      name: "email",
                      placeholder: "Email"
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("email"),
                      expression: "errors.has('email')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The email is required.")]
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "0", "unchecked-value": "1" },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("Account "),
                        _vm.status == 0
                          ? _c("span", [_vm._v("Active")])
                          : _vm._e(),
                        _vm.status != 0
                          ? _c("span", [_vm._v(" Inactive")])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle ml-2 mt-3 mb-2"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error. Please contact admin.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Edit")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "editPasswordModal",
          attrs: {
            id: "editManagerPassword",
            title: "Edit Password",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.passwordvalidateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("p", { staticClass: "text-muted" }, [
                _vm._v("Enter password and confirmation")
              ]),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12 mb-2" } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-lock" })
                              ])
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            ref: "password",
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              placeholder: "Password",
                              name: "password"
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-lock" })
                              ])
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password2,
                                expression: "password2"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              placeholder: "Password Confirmation",
                              "data-vv-as": "password",
                              name: "password2"
                            },
                            domProps: { value: _vm.password2 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password2 = $event.target.value
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.passworderror,
                    expression: "passworderror"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.passworderror,
                      expression: "passworderror"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error. Weak password or do not match. Try again.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Edit Password")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }