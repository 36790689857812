<template>
  <div class="animated fadeIn">
    <b-card>
      <div>
      <h4 class="d-inline"><i class="fa fa-users"></i> All Managers</h4>
      <router-link class="float-right btn btn-success" :to="{ name: 'Admin Add Manager', params: {id: $route.params.id}}">Add Manager <i class="fa fa-plus"></i></router-link></div>
      <hr class="my-4">
      <v-client-table :columns="columns" :data="managerData" :options="options" :theme="theme" id="dataTable">
        <div slot="status" slot-scope="props">
          <span v-if="props.row.status === 0">
            <button class="btn btn-sm btn-success">Active</button>
          </span>
          <span v-if="props.row.status !== 0">
            <button class="btn btn-sm btn-danger">Inactive</button>
          </span>
        </div>
        <span slot="creation_date" slot-scope="props">{{ props.row.creation_date | moment("M/D/YYYY") }}</span>
        <div slot="actions" slot-scope="props" data-boundary="window">
          <b-button class="mr-2 btn-sm" @click="sendInfo(props.row)"><i class="fa fa-edit"></i> Edit</b-button>
          <b-button class="btn-sm" v-b-modal.editManagerPassword @click="sendManagerId(props.row.managerid)"><i class="fa fa-edit"></i> Password</b-button>
        </div>
      </v-client-table>

      <div class="clearfix mt-4">
      <h4 class="d-inline"><i class="fa fa-users"></i> All Accountants</h4>
      <router-link class="float-right btn btn-success" :to="{ name: 'Admin Add Accountant', params: {id: $route.params.id}}">Add Accountant <i class="fa fa-plus"></i></router-link></div>
      <hr class="my-4">
      <v-client-table :columns="acolumns" :data="accountantData" :options="options" :theme="theme" id="dataTable">
        <div slot="status" slot-scope="props">
          <span v-if="props.row.status === 0">
            <button class="btn btn-sm btn-success">Active</button>
          </span>
          <span v-if="props.row.status !== 0">
            <button class="btn btn-sm btn-danger">Inactive</button>
          </span>
        </div>

        <span slot="creation_date" slot-scope="props">{{ props.row.creation_date | moment("M/D/YYYY") }}</span>
        <div slot="actions" slot-scope="props" data-boundary="window">
          <b-button class="mr-2 btn-sm" @click="sendInfo(props.row)"><i class="fa fa-edit"></i> Edit</b-button>
          <b-button class="btn-sm" v-b-modal.editManagerPassword @click="sendManagerId(props.row.managerid)"><i class="fa fa-edit"></i> Password</b-button>
        </div>
      </v-client-table>

      <h4 class="mt-4"><i class="nav-icon icon-user"></i> Company Profile</h4>
      <hr>
      <b-table striped stacked :fields="fields" :items="companyData">
        <template slot="creation_date" slot-scope="data">
          {{ data.item.creation_date | moment("M/D/YYYY") }}
        </template>
      </b-table>
    </b-card>
    <b-modal id="editManager" ref="editModal" title="Edit Manager" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label>First Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
        </b-input-group>
        <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
        <label>Last Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
        </b-input-group>
        <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
        <div v-if="this.authority !== 6">
        <hr>
        <label>Authority</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
          </b-input-group-prepend>
          <select class="select-account form-control" name="authority" v-model="authority">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>

        </b-input-group>

        <i v-show="errors.has('authority')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('authority')" class="help is-danger">The authority number is required.</span>

        <label>Pass Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="passlimit" v-validate="'required'" name="passlimit" class="form-control" placeholder="dollar-sign Limit" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('passlimit')" class="help is-danger">The dollar-sign limit number is required.</span>
        </div>
        <hr>
        <label>Phone Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
        </b-input-group>
        <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>

        <label>Fax Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-model="fax" v-validate="'digits:10'" name="fax" class="form-control" placeholder="Fax Number" />
        </b-input-group>
        <i v-show="errors.has('fax')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax')" class="help is-danger">{{ errors.first('fax') }}</span>

        <hr>
        <label>Email</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
        </b-input-group>
        <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="status" value="0" unchecked-value="1"> <p class="ml-3 mb-0">Account <span v-if="status == 0">Active</span><span v-if="status != 0"> Inactive</span></p></b-form-checkbox>
        </b-input-group>

        <i v-show="error" class="fa fa-exclamation-triangle ml-2 mt-3 mb-2"></i><span v-show="error" class="help is-danger">Error. Please contact admin.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit</b-button>
      </form>
    </b-modal>
    <b-modal id="editManagerPassword" ref="editPasswordModal" title="Edit Password" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="passwordvalidateBeforeSubmit" class="pb-2">
        <p class="text-muted">Enter password and confirmation</p>
        <b-row class="mb-3">
          <b-col md="12 mb-2">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password" placeholder="Password" name="password" ref="password"/>
            </b-input-group>
          </b-col>
          <b-col md="12">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password2" placeholder="Password Confirmation" data-vv-as="password" name="password2" />
            </b-input-group>
          </b-col>
        </b-row>
        <i v-show="passworderror" class="fa fa-exclamation-triangle"></i><span v-show="passworderror" class="help is-danger">Error. Weak password or do not match. Try again.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Password</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
import vueSlider from 'vue-slider-component';

Vue.use(ClientTable)

export default {
  name: 'AllCompanies',
  components: {
    ClientTable,
    Event,
    vueSlider
  },
  data () {
    return {
      companyData: [],
      columns: ["status", "email", "authority", "fname", "lname", "phone", "creation_date", 'actions'],
      acolumns: ["status", "email", "fname", "lname", "phone", "creation_date", 'actions'],
      managerData: [],
      accountantData: [],
      options: {
        orderBy: { ascending:true },
        headings: {
          fname: 'First Name',
          lname: 'Last Name',
          passlimit: 'dollar-sign Limit',
          authority: 'Authority Level',
        },
        sortable: ["email", "authority", "passlimit", "fname", "lname", "status", "fax", "phone", "creation_date"],
        filterable: ["email", "authority", "passlimit", "fname", "lname", "status", "fax", "phone", "creation_date"],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 1,
          edge: false,
          nav: 'scroll'
        }
      },
      aoptions: {
        orderBy: { ascending:true },
        headings: {
          fname: 'First Name',
          lname: 'Last Name',
        },
        sortable: ["email", "fname", "lname", "status", "phone", "creation_date"],
        filterable: ["email", "fname", "lname", "status", "phone", "creation_date"],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 1,
          edge: false,
          nav: 'scroll'
        }
      },
      fields: [ ,
        {
          key: 'name',
          label: 'Company Name',
        },
        {
          key: 'rcn',
          label: 'Registration Number',
        },
        {
          key: 'gst',
          label: 'GST Number',
        },
        {
          key: 'addressline1',
          label: 'Address',
        },
        {
          key: 'addressline2',
          label: 'Address 2',
        },
        {
          key: 'city',
          label: 'City',
        },
        {
          key: 'postalcode',
          label: 'Postal Code',
        },
        {
          key: 'province',
          label: 'Province',
        },
        // {
        //   key: 'phone',
        //   label: 'Phone',
        // },
        // {
        //   key: 'fax',
        //   label: 'Fax',
        // },
        {
          key: 'creation_date',
          label: 'Creation Date',
        }
      ],
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      id: "",
      fname: "",
      lname: "",
      email: "",
      authority: 1,
      passlimit: "",
      password: "",
      password2: "",
      fax: "",
      status: "",
      phone: "",
      error: "",
      passworderror: "",
      auth: 50,
    }
  },
  methods: {
    sendInfo(row) {
      this.id = row.managerid;
      this.fname = row.fname;
      this.lname = row.lname;
      this.email = row.email;
      this.status = row.status;
      this.authority = row.authority;
      this.passlimit = row.passlimit;
      this.fax = row.fax;
      this.phone = row.phone;

      this.$refs.editModal.show()
    },
    sendManagerId(id) {
      this.id = id
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            companyid: this.$route.params.id,
            email: this.email,
            authority: this.authority,
            passlimit: this.passlimit,
            fname: this.fname,
            lname: this.lname,
            fax: this.fax,
            phone: this.phone,
            status: this.status,
          })

          this.$http.post('/admin/edit/manager', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Update Successful.', {type: 'success', duration: '3000'})
              this.$refs.editModal.hide();
              this.loadData();
            }
          })
          .catch(error => {
            this.$toasted.show('Error.', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    passwordvalidateBeforeSubmit() {
      let data = JSON.stringify({
        id: this.id,
        password: this.password,
        confirm_password: this.password2,
      })
      this.$http.post('/admin/edit/manager_password', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Password Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.editPasswordModal.hide();
          this.password = "";
          this.password2 = "";
        }
      })
      .catch(error => {
        this.passworderror = true;
      })
      return;
    },
    loadData() {
      this.$http.get('/admin/get/managers/' + this.$route.params.id)
      .then(response => {
        if (response.status === 200) {
          this.managerData = response.data.result.filter(x => x.authority <= 5);
          this.accountantData = response.data.result.filter(x => x.authority === 6);
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.companyData = [this.$session.get('company')];
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
